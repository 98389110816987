<template>
    <r-e-dialog title="选择商铺" :visible.sync="dialogVisible" top="10vh" show-footer width="1000px"
                @click-submit="clickSubmit" @click-cancel="clickCancel">
        <r-e-table ref="tableRef" class="bg-white" :columns="tableLishiColumns" :query="formSearch" :height="600"
                   @selection-change="handleSelectionChange" :dataRequest="getApartmentTableData" :pageSizes="[50]"
                   :afterDataReq="afterDataReq">
            <template slot="empty">
                <el-empty/>
            </template>
        </r-e-table>
    </r-e-dialog>
</template>

<script>
    import {getRentPage} from '@/api/shops-lease';
    import {MessageInfo, MessageError, MessageSuccess} from "@custom/message";
    export default {
        name: "layer-shops-list-dialog",
        data() {
            return {
                tableLishiColumns:[
                    {
                        type: "selection",
                        label: "#",
                        width: 80
                    },
                    {
                        label: "商铺名称",
                        width: 300,
                        render: (h, {row}) => {
                            const {name} = row;
                            return h("span", name);
                        }
                    },
                    {
                        label: "区域",
                        render: (h, {row}) => {
                            const {province, city, district} = row;
                            return h("span", province + city + district);
                        }
                    },
                    {
                        label: "建筑面积",
                        render: (h, {row}) => {
                            let {outArea} = row;
                            return h("span", outArea+"m³");
                        }
                    },
                    {
                        label: "使用面积",
                        render: (h, {row}) => {
                            let {useArea} = row;
                            return h("span", useArea+"m³");
                        }
                    },
                ],
                dialogVisible: false,
                loadingOptions: {
                    lock: true,
                    text: "请您稍后正在打开...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)"
                },
                formSearch: {
                    uuid: null,
                },
                multipleSelection: [],
                saveSelection: new Map(),
            };
        },
        components: {},
        async mounted() {},
        methods: {
            async openDialog(data) {
                const {uuid} = data;
                this.formSearch.uuid = uuid;
                this.dialogVisible = true;
            },
            getApartmentTableData(params) {
                return getRentPage(params);
            },
            afterDataReq(e){
                let page = this.$refs["tableRef"].pNumber;
                let multipleSelection = this.saveSelection.get(page);
                this.$nextTick(() => {
                    if(multipleSelection){
                        for (const {uuid} of multipleSelection) {
                            let row = e.find(v => v.uuid === uuid);
                            if (row) this.$refs["tableRef"].toggleRowSelection(row, true);
                        }
                    }
                });
            },
            clickSubmit(){
                let that = this;
                let saveSelection = this.saveSelection;
                let arr = [];
                for (const [key, value] of saveSelection) {
                    arr = [...arr, ...value];
                }
                if (arr.length > 0) {
                    const h = this.$createElement;
                    let HName = arr.map(i => h('div', null, i.name));
                    const uuids = arr.map(i => i.uuid);
                    this.$msgbox({
                        title: '消息',
                        message: h('p', null, [
                            h('div', null, '您选择了' + arr.length + '个商铺,分别是:'),
                            ...HName,
                            h('div', null, '是否创建合同')
                        ]),
                        showCancelButton: true,
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                    }).then(() => {
                        that.clickCancel();
                        that.$emit("submit", {uuid:uuids.join(";")});
                    }).catch(() => console.log("取消"));
                }else {
                    MessageError("请选择商铺");
                }
            },
            clickCancel(){
                this.dialogVisible = false;
                this.multipleSelection = [];
                this.saveSelection = new Map();
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
                let page = this.$refs["tableRef"].pNumber;
                this.saveSelection.set(page,val);
            },
        },
        watch: {

        }
    }
</script>

<style scoped>

</style>