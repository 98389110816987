import request from '@http';

// 2022/06/29 王江毅 租户详情
export function getLeasorDetail(params) {
    return request({
        method: 'GET',
        url: '/api/shop/leasor/detail',
        params
    });
}

// 2022/06/29 王江毅 房间当前竞价信息
export function getApartmentBidding(params) {
    return request({
        method: 'GET',
        url: '/api/shop/bidding/apartment/bidding',
        params
    });
}

// 2022/06/29 王江毅 房间历史竞价信息
export function getApartmentBiddingList(params) {
    return request({
        method: 'GET',
        url: '/api/shop/bidding/apartment/bidding/list',
        params
    });
}

// 2022/07/01 王江毅 添加竞价信息
export function biddingAdd(data) {
    return request({
        method: 'POST',
        url: '/api/shop/bidding/bidding/add',
        data
    });
}

// 2022/07/04 王江毅 合同签订确认基本信息
export function signLeasor(data) {
    return request({
        method: 'POST',
        url: '/api/shop/contract/pc/sign/leasor',
        data
    });
}

// 2022/07/05 王江毅 签订合同前选择房间
export function signApartment(data) {
    return request({
        method: 'POST',
        url: '/api/shop/contract/pc/sign/apartment',
        data
    });
}

// 2022/07/05 王江毅 拟定合同
export function signContract(data) {
    return request({
        method: 'POST',
        url: '/api/shop/contract/pc/sign/contract',
        data
    });
}

// 2022/07/05 王江毅 查询合同账单（缴纳保证金）
export function contractPaybill(params) {
    return request({
        method: 'GET',
        url: '/api/shop/paybill/pc/contract/paybill',
        params
    });
}

// 2022/07/12 王江毅 根据房间查询合同账单
export function apartmentPaybill(params) {
    return request({
        method: 'GET',
        url: '/api/shop/paybill/page/paybill/apartment',
        params
    });
}

// 2022/07/12 王江毅 核销账单
export function cancelPaybill(data) {
    return request({
        method: 'POST',
        url: '/api/shop/paybill/pc/cancel',
        data
    });
}
// 2022/07/13 王江毅 上传合同照片
export function contractPicture(data) {
    return request({
        method: 'POST',
        url: '/api/shop/contract/pc/sign/contract/picture',
        data
    });
}

// 2022/07/13 王江毅 根据房间查关联房间
export function apartmentset(params) {
    return request({
        method: 'GET',
        url: '/api/shop/apartment/apartmentset',
        params
    });
}

// 2022/07/13 王江毅 查询未租房间
export function getRentPage(params) {
    return request({
        method: 'GET',
        url: '/api/shop/apartment/rent/page',
        params
    });
}

// 2022/07/13 王江毅 商铺合同导出
export function contractExport(uuid) {
    return request({
        method: 'GET',
        url: `/api/shop/contract/pc/contract/expord/word/${uuid}`,
        responseType: 'blob'
    })
}

// 2022/07/15 王江毅 商铺发布
export function shopPublish(data) {
    return request({
        method: 'POST',
        url: '/api/shop/apartment/publish/save',
        data
    });
}