import { render, staticRenderFns } from "./layer-shops-list-dialog.vue?vue&type=template&id=34c67dd5&scoped=true&"
import script from "./layer-shops-list-dialog.vue?vue&type=script&lang=js&"
export * from "./layer-shops-list-dialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34c67dd5",
  null
  
)

export default component.exports